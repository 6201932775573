import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {EmployeesService} from '../../../core/services/employees.service';
import {downloadFile, pdf} from '../../../../helper';
import {DocumentsService} from '../../../core/services/documents.service';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employeesService: EmployeesService,
    public documentsService: DocumentsService,
  ) { }

  ngOnInit(): void {
  }

  downloadInvoice($event: MouseEvent, button: any): void {
    button.isLoading = true;
    this.employeesService.getInvitationsPdf(this.data.employee.id).subscribe(r => {
      downloadFile(r);
      button.isLoading = false;
    }, error => {
      button.isLoading = false;
    });
  }

  downloadCredentials($event: MouseEvent, button: any): void {
    button.isLoading = true;
    this.documentsService.getAll().subscribe(r => {
      const document = r.find(f => f.title.includes('Poverenie oprávnenej osoby'));
      if (document) {
        this.documentsService.downloadEmployeeDocument(document.id, this.data.employee.id).subscribe(d => {
          downloadFile(d);
          const dataRequest = {
            value: [this.data.employee.id]
          };
          this.documentsService.generationRequest(dataRequest).subscribe(r => {
          });
          button.isLoading = false;
        }, error => {
          button.isLoading = false;
        });
      }
    });

  }
}
