import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  appSnackBar = new Subject<string>();

  snack(message: string): void {
    this.appSnackBar.next(message);
  }
}
