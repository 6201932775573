import { Component, OnDestroy, OnInit } from '@angular/core';
import { MENUITEMS } from '../../../core/data/menu-items';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { FirmService } from '../../../core/services/firm.service';
import { UserService } from '../../../core/services/user.service';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ImportantGuidelinesComponent } from '../../shared/important-guidelines/important-guidelines.component';
import { MatDialog } from '@angular/material/dialog';
import { DocumentsService } from '../../../core/services/documents.service';
import {Guidance} from '../../../core/models/domain/documents';
import {ViewportScroller} from '@angular/common';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  template: `<div class="dashboard-wrap">
                <app-snack-bar></app-snack-bar>
                <div class="container">
                  <app-header></app-header>
                  <app-menu [menuItems]="menuItems"></app-menu>
                  <router-outlet></router-outlet>
                  <app-footer></app-footer>
                  <app-back-to-top></app-back-to-top>
                </div>
              </div>`,
})
export class DashboardComponent implements OnInit, OnDestroy {
  menuItems = MENUITEMS;
  guidelines: Guidance[] = [];
  routerSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    private firmService: FirmService,
    private userService: UserService,
    private titleService: Title,
    private router: Router,
    public dialog: MatDialog,
    private documentsService: DocumentsService,
    private viewportScroller: ViewportScroller
  ) {
    router.events.pipe(
      filter((e: any): e is Scroll => e instanceof Scroll)
    ).subscribe(e => {
      if (e.position) {
        viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        viewportScroller.scrollToAnchor(e.anchor);
      } else {
        const url = e.routerEvent.url.substr(1, 7);
        if (url === 'setting' || url === 'documen') {
        } else {
          viewportScroller.scrollToPosition([0, 0]);
        }

      }
    });
  }

  ngOnInit(): void {
    this.firmService.firm = this.route.snapshot.data.firm;
    this.userService.user = this.route.snapshot.data.user;
    const title = this.firmService.firm.name + ' | Osobnyudaj.sk';
    this.titleService.setTitle(title);
    this.getAllGuidelines();
  }

  showGuidelines(): void {
    const dialogRef = this.dialog.open(ImportantGuidelinesComponent, {
      backdropClass: 'guidelines-dialog',
      width: '100%',
      data: {
        guidelines: this.guidelines
      },
      maxWidth: '1100px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.routerSubscription.unsubscribe();
        this.getAllGuidelines();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.guidelines && this.guidelines.length > 0) {
      this.routerSubscription.unsubscribe();
    }
  }

  private getAllGuidelines(): void {
    this.documentsService.getAllGuidelinesNotRead().subscribe(event => {
      this.guidelines = event;
      if (this.guidelines && this.guidelines.length > 0) {
        this.showGuidelines();
        this.routerSubscription = this.router.events.subscribe(e => {
          if (e instanceof NavigationEnd) {
            if (this.guidelines && this.guidelines.length > 0) {
              this.showGuidelines();
            }
          }
        });
      }
    });
  }
}
