import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SettingComponent} from './setting.component';
import {ThemeModule} from '../../theme/theme.module';
import {SharedModule} from '../../shared/shared.module';
import {Route, RouterModule} from '@angular/router';
import {FirmResolver} from '../../../core/resolver/firm.resolver';
import {UserResolver} from '../../../core/resolver/user.resolver';
import {MaterialModule} from '../../modules/material/material.module';
import {AuthGuard} from '../../../core/guards/auth.guard';
import { SettingsIndexComponent } from './settings-index/settings-index.component';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SettingsNavComponent } from './settings-nav/settings-nav.component';

const routes: Route[] = [
  {
    path: '',
    component: SettingComponent,
    canActivate: [AuthGuard],
    resolve: {firm: FirmResolver, user: UserResolver},
    children: [
      {
        path: '',
        loadChildren: () => import ('./settings-options/settings-options.module').then(m => m.SettingsOptionsModule),
      },
    ],
  },
];

@NgModule({
  declarations: [
    SettingsIndexComponent,
    SettingsMenuComponent,
    SettingsNavComponent
  ],
  imports: [
    CommonModule,
    ThemeModule,
    SharedModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
    exports: [
        SettingsIndexComponent,
        SettingsMenuComponent,
        SettingsNavComponent
    ],
  providers: [FirmResolver, UserResolver]
})

export class SettingModule {
}
