import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download',
  template: `<div class="download-wrap">
                <app-simple-header></app-simple-header>
                <app-snack-bar></app-snack-bar>
                <div class="container">
                  <router-outlet></router-outlet>
                </div>
              </div>`,
})
export class DownloadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
