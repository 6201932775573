import {AngularEditorConfig} from '@kolkov/angular-editor';

export const EDITORCONFIG: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: 'auto',
  minHeight: '300',
  maxHeight: 'auto',
  width: 'auto',
  minWidth: '0',
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  placeholder: 'Sem napíšte text',
  defaultParagraphSeparator: '',
  defaultFontName: 'arial',
  defaultFontSize: '',
  customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ],
  uploadUrl: 'v1/image',
  uploadWithCredentials: false,
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    [
      'strikeThrough',
      'heading',
      'fontName',
    ],
    [
      'customClasses',
      'insertImage',
      'insertVideo',
      'insertHorizontalRule',
      'removeFormat',
    ]
  ]
};
