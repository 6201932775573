import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SnackBarService} from '../../../core/services/snack-bar.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Center, InformationSystems, Job, Training} from '../../../core/models/domain/employees';
import {EnterPhoneComponent} from '../enter-phone/enter-phone.component';
import {NewJobComponent} from '../new-job/new-job.component';
import {JobsService} from '../../../core/services/jobs.service';
import {InformationSystemsService} from '../../../core/services/information-systems.service';
import {Router} from '@angular/router';
import {CentersService} from '../../../core/services/centers.service';
import {TestGroupService} from '../../../core/services/test-group.service';
import {EmployeesService} from '../../../core/services/employees.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatSelectChange} from '@angular/material/select';
import * as Mailcheck from 'mailcheck';
import {EmailConfirmationComponent} from '../email-confirmation/email-confirmation.component';
import {TestTypes} from '../../../core/models/domain/data';
import {downloadFile} from '../../../../helper';
import {MatSlideToggle, MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Animations} from '../../../core/data/animations';

@Component({
  selector: 'app-new-employee',
  templateUrl: './new-employee.component.html',
  styleUrls: ['./new-employee.component.scss'],
  animations: [
    Animations.slideInOutAnimeTrigger,
    Animations.opacityAnimeTrigger,
  ]
})
export class NewEmployeeComponent implements OnInit, AfterViewInit {
  formGroup: FormGroup;
  isLoading = false;
  submitted = false;
  noEmail = false;
  suggestion: string;
  emptyTestTypes = false;
  shake = false;
  viewStudentVideos = false;
  centers: Center[];
  jobs: Job[];
  testTypes: TestTypes[] = [];
  informationSystems: InformationSystems[];

  @ViewChild('slide', { static: false }) slide: MatSlideToggle;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    public jobsService: JobsService,
    private informationSystemsService: InformationSystemsService,
    public employeesService: EmployeesService,
    private testGroupService: TestGroupService,
    private centersService: CentersService,
    public dialogRef: MatDialogRef<NewEmployeeComponent>,
    private snackBarService: SnackBarService,
    private cdr: ChangeDetectorRef
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.prepareForm();
    this.getIs();
    this.getTestGroup();
    this.getCenters();
    this.getJobs();
    this.jobAction();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  getIs(): void {
    this.informationSystemsService.getAllIs().subscribe(r => {
      this.informationSystems = r;
    }, error => {
      this.snackBarService.snack('Nastala chyba pri načítaní informačných systémov.');
    });
  }

  getCenters(): void {
    this.centersService.getAll().subscribe(r => {
      this.centers = r;
      if (this.centers && this.centers.length > 0) {
        this.formGroup.patchValue({
          centerId: this.centers[0].id,
        });
      }
    }, error => {
      this.snackBarService.snack('Nastala chyba pri načítaní centra zamestnanca.');
    });
  }

  getTestGroup(): void {

    this.testGroupService.getTestTypes().subscribe(r => {
      this.testTypes = r;
      if (this.formGroup.get('isResponsiblePerson').value === '1') {
        this.testTypes.forEach(f => {
          if (f.testGroups.length === 1) {
            f.select = f.testGroups[0].id;
            f.one = true;
          }
        });
      }
    }, error => {
      this.snackBarService.snack('Nastala chyba pri načítaní typu e-školenia.');
    });
  }

  getJobs(): void {
    this.jobsService.getAll().subscribe(jobs => {
      this.jobs = jobs;
    }, error => {
      this.snackBarService.snack('Nastala chyba pri načítaní pracovných pozícii.');
    });
  }

  jobAction(): void {
    this.jobsService.jobsChange.subscribe(job => {
      this.getJobs();

      this.formGroup.patchValue({
        jobId: job.id,
        is: job.systems && job.systems.map(system => {
          return system.id;
        })
      });
    });
  }

  prepareForm(): void {
    this.formGroup = this.formBuilder.group({
      personalNumber: '',
      name: ['', Validators.required],
      email: ['', Validators.email],
      phone: '',
      jobId: [null, Validators.required],
      centerId: [null, Validators.required],
      testTypes: [],
      isResponsiblePerson: '1',
      downloadInvation: '',
      is: []
    });

  }

  get f(): any {
    return this.formGroup.controls;
  }

  onsubmit(): void {
    this.submitted = true;
    this.isLoading = true;

    if (this.formGroup.invalid) {
      this.isLoading = false;
      setTimeout(() => {
        const firstElementWithError = document.querySelector('.invalid.invalid-color');
        this.scrollTo(firstElementWithError);
      }, 200);
      this.snackBarService.snack('Vyplňte prosím všetky požadované údaje');
      return;
    }

    if (this.formGroup.get('isResponsiblePerson').value === '1' && this.testTypes && this.testTypes.length > 0) {
      const value = this.formGroup.get('testTypes').value;
      if (!value) {
        this.isLoading = false;
        this.emptyTestTypes = true;
        this.snackBarService.snack('Vyberte prosím typ e-školení a testovaciu skupinu');
        return;
      }
    }

    if (this.suggestion) {
      const dialogRef = this.dialog.open(EmailConfirmationComponent, {
        width: '100%',
        maxWidth: '400px',
        data: {
          suggestion: this.suggestion,
          email: this.formGroup.get('email').value
        },
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.suggestion = null;
          this.formGroup.get('email').patchValue(result);
          this.employeeStore();
        }
      });
      return;
    }
    this.employeeStore();

  }

  employeeStore(): void {

    this.employeesService.store(this.formGroup.value).subscribe(r => {
      this.router.navigate(['/employees']).then(() => {
        this.snackBarService.snack('Zamestnanec bol pridaný');

        if (this.formGroup.get('downloadInvation').value) {
          this.employeesService.getInvitationsPdf(r.id).subscribe(i => {
            downloadFile(i);
            this.snackBarService.snack('Pozvánka bola stiahnutá');
            this.isLoading = false;
          }, error => {
            this.snackBarService.snack('Nastala chyba pri sťahovaní pozvánky');
            this.isLoading = false;
          });
        }

        this.dialogRef.close();
      });
    }, error => {
      this.isLoading = false;
      if (error.error.errors && error.error.errors.name[0] === 'The name has already been taken.') {
        this.snackBarService.snack('Zamestnanec sa už v systéme nachádza');
      } else {
        this.snackBarService.snack('Nastala chyba');
      }

    });
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  suggestEmail(): void {

    const email = this.formGroup.value.email;
    const domains = [
      'gmail.com',
      'centrum.sk',
      'azet.sk',
      'post.sk',
      'zoznam.sk',
      'pobox.sk',
      'hotmail.com',
      'stonline.sk',
      'atlas.sk',
      'yahoo.com',
      'seznam.cz',
    ];
    const topLevelDomains = ['com', 'sk'];

    const check = Mailcheck.run({
      email,
      domains,
      topLevelDomains,
      suggested: (suggestion) => {
        return suggestion;
      },
      empty: () => {
        return false;
      }
    });

    if (check && check.full) {

      const gmail = email.split('@')[1];
      if (gmail === 'gmail.sk') {
        check.full = check.address + '@gmail.com';
      }

      this.suggestion = check.full;
      this.shake = true;
      setTimeout(() => {
        this.shake = false;
      }, 1000);
    } else {
      this.suggestion = null;
    }
  }

  suggestionClick(): void {
    this.formGroup.get('email').patchValue(this.suggestion);
    this.suggestion = null;
  }

  addTelNumber($event: MouseEvent): void {
    const dialogRef = this.dialog.open(EnterPhoneComponent, {
      maxWidth: '470px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.phone && result.phone.internationalNumber) {
          this.noEmail = true;
          this.formGroup.patchValue({
            phone: result.phone.internationalNumber
          });
        }
        if (result.downloadInvation === true) {
          this.noEmail = true;
          this.formGroup.patchValue({
            downloadInvation: result.downloadInvation
          });
        }

      }
    });
  }

  addJob($event: MouseEvent): void {
    this.dialog.open(NewJobComponent, {
      width: '100%',
      maxWidth: '1100px',
      autoFocus: false,
    });
  }

  changeJobs($event: any): void {
    this.jobsService.getJobIs($event).subscribe(r => {
      if (r) {
        const is = [];
        r.forEach(f => {
          this.informationSystems.forEach(i => {
            if ( i.id === f.id ) {
              is.push(f.id);
            }
          });
        });
        this.formGroup.get('is').patchValue(is);
      }
    });
  }

  changeCheckbox($event: MatCheckboxChange, item: any, select: any): void {
    if ($event.checked) {
      this.emptyTestTypes = false;
      if (select.value) {
        let value = this.formGroup.get('testTypes').value;
        if (!value) {
          value = [];
        }
        const data = {
          id: item.id,
          name: item.name,
          testGroup: select.value
        };
        value.push(data);
        this.formGroup.get('testTypes').patchValue(value);
      }
      if (item && item.id === 4) {
        this.viewStudentVideos = true;
        this.formGroup.addControl('canViewStudentVideos', this.formBuilder.control(false));
        this.formGroup.get('canViewStudentVideos').patchValue(true);

        setTimeout(() => {
          this.slide.checked = true;
        }, 1);
      }

    } else {
      let value = this.formGroup.get('testTypes').value;
      if (value) {
        value = value.filter(f => f.id !== item.id);
        if (value.length === 0) {
          value = null;
        }
        this.formGroup.get('testTypes').patchValue(value);
      }
      if (item && item.id === 4) {
        this.viewStudentVideos = false;
        this.formGroup.removeControl('canViewStudentVideos');
      }
    }
  }

  changeSelect($event: MatSelectChange, check: any, item: any): void {
    this.emptyTestTypes = false;
    let value = this.formGroup.get('testTypes').value;
    if (!value) {
      value = [];
      const data = {
        id: item.id,
        name: item.name,
        testGroup: $event.value
      };
      value.push(data);
      this.formGroup.get('testTypes').patchValue(value);
    } else {
      const data = value.find(f => f.id === item.id);
      if (!data) {
        const newData = {
          id: item.id,
          name: item.name,
          testGroup: $event.value
        };
        value.push(newData);
      } else {
        data.testGroup = $event.value;
      }
      this.formGroup.get('testTypes').patchValue(value);
    }
  }

  slideToggle($event: MatSlideToggleChange): void {
    if ($event.checked) {
      this.formGroup.get('canViewStudentVideos').patchValue(true);
    } else {
      this.formGroup.get('canViewStudentVideos').patchValue(false);
    }
  }
}
