import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {State} from '../models/state';
import {environment} from '../../../environments/environment';
import {Paginate} from '../models/paginate';
import {Employee} from '../models/domain/employees';
import {Observable, Subject} from 'rxjs';
import {isEmpty, prepareFilter, prepareFilterJson} from '../../../helper';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {
  signEmployee: Employee;

  state: State<any> = {
    page: 0,
    pageSize: 50,
    queryString: '',
    filter: null
  };

  stateTest: State<any> = {
    page: 0,
    pageSize: 50,
    queryString: '',
    filter: null
  };

  employeeChangeIs = new Subject<Employee>();
  employeeChangeIsGroup = new Subject<boolean>();
  employeeChangeUpdate = new Subject<Employee>();
  employeeChangeStore = new Subject<Employee>();

  constructor(private http: HttpClient) {
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}`);
  }

  paginate(): Observable<Paginate<Employee>> {
    const f = JSON.parse(JSON.stringify(this.state));
    f.page++;

    let httpParams = prepareFilter(f);

    if (!isEmpty(f.filter)) {
      httpParams = prepareFilterJson(httpParams, f.filter, 'filter');
    }

    return this.http.get<Paginate<Employee>>(`${environment.baseUrl}/employee/paginate`, {params: httpParams});
  }

  paginateTest(): Observable<Paginate<Employee>> {
    const f = JSON.parse(JSON.stringify(this.stateTest));
    f.page++;

    let httpParams = prepareFilter(f);

    if (!isEmpty(f.filter)) {
      httpParams = prepareFilterJson(httpParams, f.filter, 'filter');
    }

    return this.http.get<Paginate<Employee>>(`${environment.baseUrl}/employee/setting-paginate`, {params: httpParams});
  }

  simpleEmployee(): Observable<Paginate<Employee>> {
    const state: State<any> = {
      page: 0,
      pageSize: 5,
      queryString: ''
    };
    const f = JSON.parse(JSON.stringify(state));
    const httpParams = prepareFilter(f);
    return this.http.get<Paginate<Employee>>(`${environment.baseUrl}/employee/paginate`, {params: httpParams});
  }

  store(formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/employee/store`, formData).pipe(map(r => {
      this.employeeChangeStore.next(r);
      return r;
    }));
  }

  allCanLoginToEducationByEmail(email: string): Observable<Employee[]> {
    return this.http.get<Employee[]>(`${environment.baseUrl}/employee/allCanLoginToEducationByEmail/${email}`);
  }

  allCanLoginToEducationByEmployeeEmail(email: string): Observable<Employee[]> {
    return this.http.get<Employee[]>(`${environment.baseUrl}/employee/allCanLoginToEducationByEmployeeEmail/${email}`);
  }

  update(id: number, formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/employee/${id}/update`, formData).pipe(map(r => {
      this.employeeChangeUpdate.next(r);
      return r;
    }));
  }

  simpleUpdate(id: number, formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/employee/${id}/update`, formData);
  }

  updateTestTypes(id: number, formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/employee/${id}/updateTestTypes`, formData);
  }

  destroy(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}/employee/destroy/${id}`);
  }

  getRenewal(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/recover`);
  }

  syncIs(id: number, formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/employee/${id}/syncIs`, formData).pipe(map(r => {
      this.employeeChangeIs.next(r);
      return r;
    }));
  }

  syncIsGroup(id: number, formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/employee/${id}/syncIs`, formData);
  }

  changeJob(id: number, formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/employee/${id}/changeJob`, formData);
  }

  set(id: number, key: string, formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/employee/${id}/set/${key}`, formData);
  }

  importEmployees(file: any): Observable<any> {
    const uploadedFile = new FormData();
    uploadedFile.append('file', new Blob([file], {type: 'text/csv'}), 'import');
    return this.http.post<any>(`${environment.baseUrl}/import/store`, uploadedFile);
  }

  getEmployeeCheck(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/import/employee/check`);
  }

  storeEmployee(formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/import/employee/store`, formData);
  }

  setInvitedAt(formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/employee/setInvitedAt`, formData);
  }

  getActivity(limit: number, testTypeId: number = null): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/activity?limit=${limit}${testTypeId ? '&tesTypeId=' + testTypeId : ''}`);
  }

  getEmployeesWithoutIs(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/withoutIs`);
  }

  getEmployeesWithoutJob(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/withoutJob`);
  }

  changeState(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/changeState/${id}`);
  }

  getHistory(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/audit/employee/${id}`);
  }

  getSignedDocuments(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/signedDocuments`);
  }

  getCertificates(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/certificates`);
  }

  getInvitations(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/invitations`);
  }

  getInvitationsPdf(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/invitation/pdf`);
  }

  getInvitationsPdfTest(id: number, testTypeId: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/invitation/pdf/${testTypeId}`);
  }

  getCertificatesPdf(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/certificate/pdf`);
  }

  getSuccessCertificatePdf(id: number, certificateId: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/certificate/${certificateId}/pdf`);
  }

  getIs(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/is`);
  }

  getUnexpiredInvitations(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/unexpiredInvitations`);
  }

  getActiveInvitations(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/activeInvitations`);
  }

  getAudits(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/audit/`);
  }

  resendInvitation(id: number, type: string, formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/employee/${id}/resendInvitation/${type}`, formData);
  }

  getTestType(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/testTypes`);
  }

  loginEmployee(id: number): Observable<string> {
    return this.http.get<string>(`${environment.baseUrl}/employee/${id}/login`);
  }

  loggedEmployee(): Observable<Employee> {
    return this.http.get<Employee>(`${environment.baseUrl}/employee/loggedEmployee`);
  }
}
