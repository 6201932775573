import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorInterceptor} from './core/interceptor/error.interceptor';
import {TokenInterceptor} from './core/interceptor/token.interceptor';
import {SettingComponent} from './views/page/setting/setting.component';
import {SignpostComponent} from './views/page/signpost/signpost.component';
import {NonAuthZoneComponent} from './views/page/non-auth-zone/non-auth-zone.component';
import {DashboardComponent} from './views/page/dashboard/dashboard.component';
import {ThemeModule} from './views/theme/theme.module';
import {SharedModule} from './views/shared/shared.module';
import {SettingModule} from './views/page/setting/setting.module';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {getTranslatePaginatorIntl} from '../helper';
import {DownloadModule} from './views/page/download/download.module';
import {DownloadComponent} from './views/page/download/download.component';
import {DatePipe} from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NonAuthZoneComponent,
    SignpostComponent,
    SettingComponent,
    DownloadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ThemeModule,
    SharedModule,
    SettingModule,
    DownloadModule
  ],
  providers: [
    DatePipe,
    {provide: MatPaginatorIntl, useValue: getTranslatePaginatorIntl()},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
