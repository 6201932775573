import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MENUSETTINGS, NAVSETTINGS} from '../../../../core/data/menu-items';
import {User} from '../../../../core/models/domain/user';
import {UserService} from '../../../../core/services/user.service';

@Component({
  selector: 'app-settings-nav',
  templateUrl: './settings-nav.component.html',
  styleUrls: ['./settings-nav.component.scss']
})
export class SettingsNavComponent implements OnInit {
  user: User;
  menuItems = NAVSETTINGS;
  menu = MENUSETTINGS;
  menuLink = [];
  @Input() type: string;

  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.user = this.userService.user;
    this.menuItems.forEach( f => {
      this.user.testTypes.forEach( e => {
        if (f.testType === e.name) {
          f.isType = true;
        }
       });
    });
  }

  setType($event: MouseEvent, item: any): void {
    this.menuLink = [];
    this.menu.forEach( f => {
      if (f.types.includes(item.type)) {
        this.menuLink.push(f);
      }
    });

    this.router.navigate(['/setting/', this.menuLink[0].routerLink , item.type]).then(() => {});
  }
}
