import {trigger, state, style, transition, animate, group} from '@angular/animations';

export const Animations = {
  scaleAnimeTrigger: trigger('scaleAnime', [
    transition(':enter', [
      style({
        opacity: 0,
        transform: 'scale(0.85)'
      }),
      animate('0.3s ease')
    ]),
    transition(':leave', [
      animate(
        '0.3s ease',
        style({
          opacity: 0,
          transform: 'scale(0.85)'
        })
      )
    ])
  ]),
  opacityAnimeTrigger: trigger('opacityAnime', [
    transition(':enter', [
      style({
        opacity: 0,
      }),
      animate('0.3s ease')
    ]),
    transition(':leave', [
      animate(
        '0.3s ease',
        style({
          opacity: 0,
        })
      )
    ])
  ]),
  slideInOutTrigger: trigger('slideInOut', [
    state('in', style({height: '*', opacity: 0})),
    transition(':leave', [
      style({height: '*', opacity: 1}),

      group([
        animate(100, style({height: 0})),
        animate('100ms ease-in-out', style({'opacity': '0'}))
      ])

    ]),
    transition(':enter', [
      style({height: '0', opacity: 0}),

      group([
        animate(100, style({height: '*'})),
        animate('100ms ease-in-out', style({'opacity': '1'}))
      ])

    ])
  ]),
  slideInOutLongAnimeTrigger: trigger('slideInOutLong', [
    state('in', style({height: '*', opacity: 0})),
    transition(':leave', [
      style({height: '*', opacity: 1}),

      group([
        animate(300, style({height: 0})),
        animate('100ms ease-in-out', style({'opacity': '0'}))
      ])

    ]),
    transition(':enter', [
      style({height: '0', opacity: 0}),

      group([
        animate(300, style({height: '*'})),
        animate('100ms ease-in-out', style({'opacity': '1'}))
      ])

    ])
  ]),
  slideInOutAnimeTrigger: trigger('slideDownUp', [
    transition(':enter', [style({ height: 0 }), animate(100)]),
    transition(':leave', [animate(300, style({ height: 0 }))]),
  ]),

};
