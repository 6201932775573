import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {isEmpty, prepareFilter, prepareFilterJson} from '../../../helper';
import {Employee} from '../models/domain/employees';
import {Paginate} from '../models/paginate';
import {State} from '../models/state';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  state: State<any> = {
    page: 0,
    pageSize: 50,
    queryString: '',
    filter: null
  };

  constructor(private http: HttpClient) { }

  paginate(): Observable<Paginate<Employee>> {
    const f = JSON.parse(JSON.stringify(this.state));
    f.page++;

    let httpParams = prepareFilter(f);

    if (f.sort) {
      httpParams = prepareFilterJson(httpParams, f.sort, 'sort');
    }

    if (!isEmpty(f.filter)){
      httpParams = prepareFilterJson(httpParams, f.filter, 'filter');
    }

    return this.http.get<Paginate<Employee>>(`${environment.baseUrl}/employee/paginate`, {params: httpParams});
  }

  getAll(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/document/all`);
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/document/${id}`);
  }

  getGuidelines(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/document/guideline/all`);
  }

  getAllGuidelinesNotRead(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/document/guideline/all/notRead`);
  }

  sendGuideline(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/document/guideline/send/${id}`);
  }

  getSecurity(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/document/security/all`);
  }

  download(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/document/${id}/template`);
  }

  generationRequest(data: any): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}/document/generationRequest/store`, data);
  }

  downloadEmployeeDocument(documentId: number, employeeId: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/document/${documentId}/employee/${employeeId}`);
  }

  downloadEmployeeDocumentAll(id: number): Observable<any>  {
    return this.http.get<any>(`${environment.baseUrl}/document/employee/${id}`);
  }

  // tslint:disable-next-line:typedef
  downloadSecurity(name: string) {
    // @ts-ignore
    return this.http.get<any>(`${environment.baseUrl}/document/security/download/${name}`, {responseType: 'blob'});
  }

  readGuidelines(guideline): Observable<void> {
    const formData = {
      guidelineId: guideline.id
    };
    return this.http.post<void>(`${environment.baseUrl}/document/guideline/read`, formData);
  }

  downloadGuidelines(id): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/document/guideline/download-file/${id}`);
  }

  signDocument(formData): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}/document/sign`, formData);
  }

  massAction(formData): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}/mass-action/store`, formData);
  }

}
