import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from '../../../core/models/domain/menu-items';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ImportantNoticesComponent} from '../../shared/important-notices/important-notices.component';
import {EmployeesService} from '../../../core/services/employees.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  active = false;
  employeeNotices: number;
  employeesNotJob: number;

  @Input() menuItems: MenuItem[];

  constructor(
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private employeesService: EmployeesService,
  ) { }

  ngOnInit(): void {
    this.getEmployees();
    this.employeeAction();
  }

  employeeAction(): void {
    this.employeesService.employeeChangeIs.subscribe(() => {
      this.getEmployees();
    });
    this.employeesService.employeeChangeIsGroup.subscribe(() => {
      this.getEmployees();
    });
  }

  showNotices($event: MouseEvent): void {
    this.dialog.open(ImportantNoticesComponent, {
      backdropClass: 'notices-dialog',
      width: '100%',
      maxWidth: '1100px',
      autoFocus: false,
    });
  }

  private getEmployees(): void {
    this.employeesService.getEmployeesWithoutIs().subscribe(r => {
      this.employeeNotices = r.length;
    });
    this.employeesService.getEmployeesWithoutJob().subscribe(r => {
      this.employeesNotJob = r.length;
    });
  }

  showMenu($event: MouseEvent): void {
    this.active = !this.active;
    document.body.classList.toggle('lock-scroll');
  }
}
