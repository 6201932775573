import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as Mailcheck from 'mailcheck';
import {SnackBarService} from '../../../core/services/snack-bar.service';
import {Animations} from '../../../core/data/animations';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
  animations: [
    Animations.scaleAnimeTrigger,
    Animations.slideInOutAnimeTrigger,
  ]
})
export class EmailConfirmationComponent implements OnInit {
  formGroup: FormGroup;
  suggestion: string;
  shake = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmailConfirmationComponent>,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.prepareForm();
  }

  private prepareForm(): void {
    this.formGroup = this.formBuilder.group({
      email: ['', Validators.email],
    });
    if (this.data && this.data.email) {
      this.formGroup.patchValue({
        email: this.data.email
      });
      this.suggestEmail();
    }
  }

  get f(): any {
    return this.formGroup.controls;
  }

  suggestEmail(): void {

    const email = this.formGroup.value.email;
    const domains = [
      'gmail.com',
      'centrum.sk',
      'azet.sk',
      'post.sk',
      'zoznam.sk',
      'pobox.sk',
      'hotmail.com',
      'stonline.sk',
      'atlas.sk',
      'yahoo.com',
      'seznam.cz',
    ];
    const topLevelDomains = ['com', 'sk'];

    const check = Mailcheck.run({
      email,
      domains,
      topLevelDomains,
      suggested: (suggestion) => {
        return suggestion;
      },
      empty: () => {
        return false;
      }
    });

    if (check && check.full) {

      const gmail = email.split('@')[1];
      if (gmail === 'gmail.sk') {
        check.full = check.address + '@gmail.com';
      }

      this.suggestion = check.full;
      this.shake = true;
      setTimeout(() => {
        this.shake = false;
      }, 1000);
    } else {
      this.suggestion = null;
    }
  }

  suggestionClick(): void {
    this.formGroup.get('email').patchValue(this.suggestion);
    this.suggestion = null;
  }

  saveEmail($event: MouseEvent): void {

    if (this.formGroup.get('email').invalid) {
      this.snackBarService.snack('Email nieje v správnom formáte, prosím skontrolujte ho.');
    }

    this.dialogRef.close(this.formGroup.value.email);

  }
}
