import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {FirmService} from '../services/firm.service';
import {Firm} from '../models/domain/firm';

@Injectable()
export class FirmResolver implements Resolve<Observable<any>> {
  constructor(private firService: FirmService) {}

  resolve(): Observable<Firm> {
    return this.firService.getFirm();
  }
}
