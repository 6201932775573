import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {InformationSystems} from '../../../core/models/domain/employees';
import {Animations} from '../../../core/data/animations';

@Component({
  selector: 'app-checkbox-is',
  templateUrl: './checkbox-is.component.html',
  styleUrls: ['./checkbox-is.component.scss'],
  animations: [
    Animations.scaleAnimeTrigger,
    Animations.slideInOutAnimeTrigger,
  ]
})
export class CheckboxIsComponent implements OnInit {
  @Input() informationSystems: InformationSystems[];
  @Input() formGroup: FormGroup;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  checkChange($event: any, is): void {
    const actData = this.formGroup.get('is').value;
    if ($event.checked) {
      let array = actData;
      if (!actData) {
        array = [];
      }
      array.push(is.id);
      this.formGroup.get('is').patchValue(array);
    } else {
      if (actData && actData.length > 1) {
        const array = actData.filter(f => f !== is.id);
        this.formGroup.get('is').patchValue(array);
      } else {
        this.formGroup.get('is').patchValue([]);
      }
    }
  }

  isChecked(is: InformationSystems): boolean {
    const isData = this.formGroup.get('is').value;
    let boll = false;
    if (isData && isData.length > 0) {
      boll = isData.includes(is.id);
    }
    return boll;
  }
}
