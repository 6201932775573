import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {EDITORCONFIG} from '../../../core/data/editor';
import {EmployeesService} from '../../../core/services/employees.service';
import {SnackBarService} from '../../../core/services/snack-bar.service';
import {Employee} from '../../../core/models/domain/employees';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-employee-note',
  templateUrl: './employee-note.component.html',
  styleUrls: ['./employee-note.component.scss']
})
export class EmployeeNoteComponent implements OnInit {
  formGroup: FormGroup;
  editorConfig = EDITORCONFIG;
  isLoading = false;
  submitted = false;
  employee: Employee;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EmployeeNoteComponent>,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private employeesService: EmployeesService,
  ) { }

  ngOnInit(): void {
    this.employee = this.data.employee;
    this.prepareForm();
  }

  prepareForm(): void {
    this.formGroup = this.formBuilder.group({
      note: '',
    });
    if (this.employee && this.employee.note) {
      this.formGroup.patchValue({
        note: this.employee.note,
      });
    }

  }

  onsubmit(): void {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }
    this.isLoading = true;
    const formData = {
      value: this.formGroup.value.note
    };
    this.employeesService.set(this.employee.id, 'note' , formData).subscribe(r => {
      this.isLoading = false;
      this.snackBarService.snack('Poznámka ku zamestnancovi bola pridaná');
      this.dialogRef.close(true);
    }, error => {
      this.isLoading = false;
    });

  }

  deleteNote($event: MouseEvent): void {
    const formData = {
      value: 'null'
    };
    this.employeesService.set(this.employee.id, 'note' , formData).subscribe(r => {
      this.isLoading = false;
      this.snackBarService.snack('Poznámka ku zamestnancovi bola odstránená');
      this.dialogRef.close(true);
    }, error => {
      this.isLoading = false;
    });
  }
}
