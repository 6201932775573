import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestGroupService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/testGroup/all`);
  }

  getTestsGroups(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/employee/${id}/tests`);
  }

  getTestTypes(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/user/testTypes`);
  }

  activateTestsGroups(id: number, formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/employee/${id}/tests/active`, formData);
  }
}
