import {HttpParams} from '@angular/common/http';

import {State} from './app/core/models/state';
import {Sort} from './app/core/models/sort';
import {Sort as MaterialSort} from '@angular/material/sort';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {FormGroup} from '@angular/forms';


// document-filter
// tslint:disable-next-line:typedef
export function prepareFilter(filter) {
  let params = new HttpParams();

  for (const key in filter) {
    if (filter.hasOwnProperty(key)) {
      if (filter[key] !== null) {
        params = params.set(key, filter[key].toString());
      }
    }
  }

  return params;
}

// sort and document-filter object
// tslint:disable-next-line:typedef
export function prepareFilterJson(httpParams: HttpParams, object: any, key: string) {
  if (object) {
    const filter = JSON.parse(JSON.stringify(object));

    Object.keys(filter).forEach((key) => {
      if (filter[key] === '') {
        delete filter[key];
      }
    });

    httpParams = httpParams.set(key, JSON.stringify(filter));
  }

  return httpParams;
}

/**
 *
 * @param page
 * @param pageSize
 * @param queryString
 * @param sort
 * @param filter
 */
// tslint:disable-next-line:max-line-length
export function prepareState(page: number, pageSize: number, queryString: string, sort: MaterialSort = null, filter: any = null): State<any> {
  return {page, pageSize, queryString, sort: prepareSort(sort.active, sort.direction), filter};
}

/**
 * @param active
 * @param direction
 */
function prepareSort(active: string, direction: any): Sort {
  if (active && direction) {
    return {sortColumn: active, sortDirection: direction};
  } else {
    return null;
  }
}

// convert image to webp
// tslint:disable-next-line:typedef
export function processImage(image: any) {
  // Load the data into an image
  return new Promise((resolve) => {
    const rawImage = new Image();

    rawImage.addEventListener('load', () => {
      resolve(rawImage);
    });

    rawImage.src = URL.createObjectURL(image);

  }).then(rawImage => {
    // Convert image to webp ObjectURL via a canvas blob
    return new Promise(resolve => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // @ts-ignore
      canvas.width = rawImage.width;
      // @ts-ignore
      canvas.height = rawImage.height;
      // @ts-ignore
      ctx.drawImage(rawImage, 0, 0);

      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/webp');
    });
  });
}

// groupBy by it
export function groupBy(list, keyGetter): any {
  const map = new Map();

  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return map;
}

// get transform to ng date
// tslint:disable-next-line:typedef
export function transformDateToNgDate(date: any) {
  return date.split('-');
}

// get isEmpty object
// tslint:disable-next-line:typedef
export function isEmpty(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

/**
 * Ad days to date
 * @param date
 * @param days
 */
// tslint:disable-next-line:typedef
export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

// custom date picker formats
export const APP_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

// trans
const transRangeLabel = (page: number, pageSize: number, length: number) => {
  // tslint:disable-next-line:triple-equals
  if (length == 0 || pageSize == 0) {
    return `0 z ${length}`;
  }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;
  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} z ${length}`;
};

// trans for pagination
// tslint:disable-next-line:typedef
export function getTranslatePaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Zobrazení na stránku:';
  paginatorIntl.nextPageLabel = 'Staršie';
  paginatorIntl.previousPageLabel = 'Novšie';
  paginatorIntl.lastPageLabel = 'Najstaršie';
  paginatorIntl.firstPageLabel = 'Najnovšie';
  paginatorIntl.getRangeLabel = transRangeLabel;

  return paginatorIntl;
}

// tslint:disable-next-line:typedef
export function scrollTo(el: Element) {
  if (el) {
    el.scrollIntoView({behavior: 'smooth', block: 'center'});
  }
}

// comment
function scrollToError(): void {
  const firstElementWithError = document.querySelector('.invalid');
  scrollTo(firstElementWithError);
}

export function pdf(x: ArrayBuffer, name: string): void {
  const downloadURL = window.URL.createObjectURL(x);
  const link = document.createElement('a');

  link.href = downloadURL;
  link.download = name + '.pdf';
  link.click();
}

export function downloadFileUrl(x: ArrayBuffer, name: string): void {
  const downloadURL = window.URL.createObjectURL(x);
  const link = document.createElement('a');

  link.href = downloadURL;
  link.download = name;
  link.click();
}

// comment
export async function scrollIfFormHasErrors(form: FormGroup): Promise<any> {
  await form.invalid;
  scrollToError();
}

// mageid
// tslint:disable-next-line:typedef
export function makeId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


export function downloadFile(r): any{
  const b64toBlob = (b64Data, contentType = r.mime, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const blob = b64toBlob(r.data, r.mine);
  const blobUrl = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  const fileName = r.name;

  downloadLink.href = blobUrl;
  downloadLink.download = fileName;
  downloadLink.click();
}
