import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EmployeesService} from '../../../core/services/employees.service';
import {Employee} from '../../../core/models/domain/employees';
import {SnackBarService} from '../../../core/services/snack-bar.service';

@Component({
  selector: 'app-important-notices',
  templateUrl: './important-notices.component.html',
  styleUrls: ['./important-notices.component.scss']
})
export class ImportantNoticesComponent implements OnInit {
  date: Date;
  isLoading = true;
  employees: Employee[];
  employeesNotJob: Employee[];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ImportantNoticesComponent>,
    private employeesService: EmployeesService,
    private snackBarService: SnackBarService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getEmployees();
    this.date = new Date();
  }

  private getEmployees(): void {
    this.employeesService.getEmployeesWithoutIs().subscribe(r => {
      this.employees = r;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.snackBarService.snack('Nastala chyba');
    });
    this.employeesService.getEmployeesWithoutJob().subscribe(r => {
      this.employeesNotJob = r;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.snackBarService.snack('Nastala chyba');
    });
  }

  show($event: MouseEvent, employee: Employee, type: string): void {
    let url = window.location.origin;
    url += '/employees/show/';
    url += employee.id;

    if (type === 'is') {
      url += '/no-is';
    } else {
      url += '/no-job';
    }

    window.open(url, '_blank');
  }
}
