import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Employee} from '../../../core/models/domain/employees';
import {Animations} from '../../../core/data/animations';
import {DocumentsService} from '../../../core/services/documents.service';
import {FirmService} from '../../../core/services/firm.service';
import {SnackBarService} from '../../../core/services/snack-bar.service';
import {Person} from '../../../core/models/domain/firm';
import {Guidance} from '../../../core/models/domain/documents';
import {downloadFile} from "../../../../helper";

@Component({
  selector: 'app-important-guidelines',
  templateUrl: './important-guidelines.component.html',
  styleUrls: ['./important-guidelines.component.scss'],
  animations: [
    Animations.slideInOutLongAnimeTrigger,
    Animations.slideInOutAnimeTrigger,
    Animations.opacityAnimeTrigger,
    Animations.scaleAnimeTrigger,
  ]
})
export class ImportantGuidelinesComponent implements OnInit {
  isLoading = true;
  read = false;
  person: Person;
  guidelines: Guidance[] = [];

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImportantGuidelinesComponent>,
    public documentsService: DocumentsService,
    private firmService: FirmService,
    private snackBarService: SnackBarService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.guidelines = this.data.guidelines;
    this.guidelines[0].slide = true;
    this.responsiblePerson();
  }

  responsiblePerson(): void {
    this.firmService.getResponsiblePerson().subscribe(r => {
      this.person = r;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.snackBarService.snack('Nastala chyba');
    });
  }

  show($event: MouseEvent, employee: Employee): void {
    let url = window.location.origin;
    url += '/employees/show/';
    url += employee.id;
    url += '/no-is';

    window.open(url, '_blank');
  }

  showGuideline($event: MouseEvent, guideline: any): void {
    guideline.slide = !guideline.slide;
  }

  remindLater(guideline: any): void {
    guideline.slide = false;
  }

  readGuidelines(guideline: any): void {
    this.read = true;
    this.isLoading = true;
    this.documentsService.readGuidelines(guideline).subscribe(r => {
      this.isLoading = false;
      this.guidelines = this.guidelines.filter(f => f.id !== guideline.id);
      if (this.guidelines.length === 0) {
        this.dialogRef.close(true);
      } else {
        this.guidelines[0].slide = true;
      }
    }, error => {
      this.isLoading = false;
      this.snackBarService.snack('Nastala chyba');
    });
  }

  closeDialog($event: MouseEvent): void {
    if (this.read) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close();
    }
  }

  download(id: number): void {
    this.isLoading = true;
    this.documentsService.downloadGuidelines(id).subscribe(r => {
      downloadFile(r);
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.snackBarService.snack('Nastala chyba');
    });
  }
}
