import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FirmService} from '../../../../core/services/firm.service';
import { Firm } from 'src/app/core/models/domain/firm';

@Component({
  selector: 'app-settings-index',
  templateUrl: './settings-index.component.html',
  styleUrls: ['./settings-index.component.scss']
})
export class SettingsIndexComponent implements OnInit, AfterViewInit {
  formGroup: FormGroup;
  firm: Firm;
  editSetting = false;
  submitted = false;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    public firmService: FirmService,
  ) { }

  ngOnInit(): void {
    this.firm = this.firmService.firm;
    this.prepareForm();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  prepareForm(): void {
    this.formGroup = this.formBuilder.group({
      name: '',
      function: '',
    });
    if (this.firm) {
      this.formGroup.patchValue({
        name: this.firm.sName,
        function: this.firm.sFunction
      });
    }
  }

  get f(): any {
    return this.formGroup.controls;
  }

  onsubmit(): void {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }
    this.isLoading = true;

    this.firmService.statutarUpdate(this.formGroup.value).subscribe(r => {
      this.isLoading = false;
    });

  }

  edit($event: MouseEvent): void {
    this.editSetting = !this.editSetting;
  }
}
