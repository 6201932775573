import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Employee, InformationSystems, Job} from '../../../../../../../core/models/domain/employees';
import {JobsService} from '../../../../../../../core/services/jobs.service';
import {Animations} from '../../../../../../../core/data/animations';
import {JobCredentialsComponent} from '../job-credentials/job-credentials.component';
import {SnackBarService} from '../../../../../../../core/services/snack-bar.service';

@Component({
  selector: 'app-job-is',
  templateUrl: './job-is.component.html',
  styleUrls: ['./job-is.component.scss'],
  animations: [
    Animations.scaleAnimeTrigger,
    Animations.slideInOutAnimeTrigger,
  ]
})
export class JobIsComponent implements OnInit, AfterViewInit {
  isLoading = false;
  submitted = false;
  shake = false;
  allJobsId: number;
  sameIs: boolean;
  job: Job;
  jobs: Job[];
  newIs: InformationSystems[];
  employees: Employee[];
  allComplete = false;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public jobsService: JobsService,
    public dialogRef: MatDialogRef<JobIsComponent>,
    private snackBarService: SnackBarService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.job = this.data.data.job;
    this.newIs = this.data.is.filter( f => this.data.formData.is.includes(f.id));
    const result = this.data.oldIs.filter(o1 => this.newIs.some(o2 => o1.id === o2.id));
    this.sameIs = result.length === this.data.oldIs.length && (result.length === this.newIs.length);
    this.getEmployees();
    this.getJobs();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  private getEmployees(): void {
    this.jobsService.getEmployees(this.job.id).subscribe(r => {
      this.employees = r;
    });
  }

  setAll(checked: boolean): void {
    this.allComplete = checked;
    this.employees.forEach(t => t.checked = checked);
    this.changeJobs();
  }

  changeCheckbox($event: any, employee): void {
    employee.checked = !!$event.checked;
    this.allComplete = this.employees.every(t => t.checked);
    this.changeJobs();
  }

  getJobs(): void {
    this.jobsService.getAll().subscribe(jobs => {
      this.jobs = jobs;
    });
  }

  onsubmit(): void {
    this.submitted = true;
    this.isLoading = true;

    const checkEmployees = this.employees.filter(t => t.check);

    this.jobsService.update(this.job.id, this.data.formData).subscribe(r => {
      if (checkEmployees && checkEmployees.length > 0) {
        const formData = {
          is: this.data.formData.is
        };
        this.jobsService.syncIs(this.job.id, formData).subscribe(f => {
          const dialogRef = this.dialog.open(JobCredentialsComponent, {
            maxWidth: '100%',
            width: '1100px',
            data: {
              data: this.data,
              employees: checkEmployees
            },
            autoFocus: false
          });

          dialogRef.afterClosed().subscribe(result => {
            this.isLoading = false;
            if (result === true) {
              this.dialogRef.close(true);
            }
          });
        });
      } else {
        const formData = {
          is: this.data.formData.is
        };
        this.jobsService.syncIs(this.job.id, formData).subscribe(f => {
          this.snackBarService.snack('Údaje pracovnej pozície boli aktualizované');
          this.dialogRef.close(true);
        });
      }
    });

  }

  changeJob($event: any, employee: Employee, job: Job): void {
    if ($event) {
      employee.checked = false;
      employee.jobId = job.id;
    } else {
      delete employee.jobId;
    }
  }

  changeJobs(): void {
    this.employees.forEach(f => {
      if (f.checked) {
        if (this.allJobsId) {
          f.jobId = this.allJobsId;
        }
      }
    });
  }

}
