import {Injectable} from '@angular/core';
import {Firm} from '../models/domain/firm';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirmService {
  firm: Firm;

  setFirm(firm: any): void {
    localStorage.setItem('firm', JSON.stringify(firm));
  }

  constructor(private http: HttpClient) {
  }

  getFirm(): Observable<Firm> {
    return this.http.get<Firm>(`${environment.baseUrl}/firm/activeFirm`);
  }

  getFirms(): Observable<Firm[]> {
    return this.http.get<Firm[]>(`${environment.baseUrl}/firm/all`);
  }

  getContactPerson(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/firm/contactPerson`);
  }

  getResponsiblePerson(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/firm/responsiblePerson`);
  }

  getContract(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/firm/contract`);
  }

  getFirmWithoutUsers(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/firm/allWithoutUsers`);
  }

  getEmployeeCount(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/firm/employeeCount`);
  }

  statutarUpdate(statutar): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}/firm/statutar/update`, statutar);
  }
}
