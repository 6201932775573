import { EmailTemplatesMenu } from '../models/domain/settings';

export const EMAILTEMPLATESMENU: EmailTemplatesMenu[] = [
  {
    id: 1,
    title: 'Pozvánka na školenie',
    isActive: true,
    type: 'invitation'
  },
  {
    id: 2,
    title: 'Pripomenutie školenia č.1',
    isActive: false,
    type: 'reminder1'
  },
  {
    id: 3,
    title: 'Pripomenutie školenia č.2',
    isActive: false,
    type: 'reminder2'
  },
  {
    id: 4,
    title: 'Pripomenutie školenia č.3',
    isActive: false,
    type: 'reminder3'
  },
  {
    id: 5,
    title: 'Upozornenie nadriadeného na neabsolvovanie školenia',
    isActive: false,
    type: 'warning'
  },
  {
    id: 6,
    title: 'Úspešné absolvovanie školenia',
    isActive: false,
    type: 'passing'
  },
  {
    id: 7,
    title: 'Posielanie dokumentov zamestnancom',
    isActive: false,
    type: 'sending'
  }
];

export const CUSTOMSMTPMENU: EmailTemplatesMenu[] = [
  {
    id: 1,
    title: 'Nastavenie pripojenia',
    isActive: false,
    symptom: 'connection'
  },
  {
    id: 2,
    title: 'Emailový podpis',
    isActive: false,
    symptom: 'signature',
  }
];

export const SETTINGTYPES: string[] = ['generally', 'gdpr', 'kb', 'cyberbullying'];
