import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import {Animations} from '../../../core/data/animations';


@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss'],
  animations: [
    Animations.scaleAnimeTrigger
  ]
})
export class BackToTopComponent implements OnInit {
  isScroll = false;

  @HostListener('window:scroll', ['$event'])
  onScrollEvent($event): void{
    this.isScroll = document.documentElement.scrollTop > 300;
  }

  constructor(
  ) { }

  ngOnInit(): void {
  }

  toTop($event: MouseEvent): void {
    window.scrollTo(0, 0);
  }
}
