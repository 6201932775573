import {Component, Inject, OnInit} from '@angular/core';
import {Employee, Job} from '../../../core/models/domain/employees';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SnackBarService} from '../../../core/services/snack-bar.service';
import {JobsService} from '../../../core/services/jobs.service';
import {EmployeesService} from '../../../core/services/employees.service';
import {DocumentsService} from '../../../core/services/documents.service';
import {Animations} from '../../../core/data/animations';
import {SuccessDialogComponent} from '../success-dialog/success-dialog.component';

@Component({
  selector: 'app-change-job-credentials',
  templateUrl: './change-job-credentials.component.html',
  styleUrls: ['./change-job-credentials.component.scss'],
  animations: [
    Animations.slideInOutAnimeTrigger,
    Animations.scaleAnimeTrigger,
  ]
})
export class ChangeJobCredentialsComponent implements OnInit {
  isLoading = false;
  submitted = false;
  employees: Employee[];
  documentEmployees: Employee[];
  documentEmployeesId: number[] = [];
  job: Job;

  constructor(
    public dialog: MatDialog,
    public jobsService: JobsService,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employeesService: EmployeesService,
    public documentsService: DocumentsService,
    public dialogRef: MatDialogRef<ChangeJobCredentialsComponent>,
  ) { }

  ngOnInit(): void {
    this.snackBarService.snack('Údaje pracovnej pozície boli aktualizované');
    this.employees = this.data.checkEmployees;
  }

  onsubmit(): void {
    this.documentEmployees = this.employees;
    this.isLoading = true;
    this.editMultiple(this.employees);
  }

  editMultiple(employees): void {
    if (employees.length > 0) {
      const employee = employees[0];

      const formData = {
        is: this.data.formData.is
      };

      this.employeesService.syncIsGroup(employee.id, formData).subscribe(r => {

        // tslint:disable-next-line:triple-equals
        this.employees = this.employees.filter(f => f.id != employees[0].id);
        this.editMultiple(this.employees);

      }, error => {
        this.snackBarService.snack('Vyskytol sa problém.');
        this.isLoading = false;
      });
    } else {
      this.employeesService.employeeChangeIsGroup.next(true);
      this.documentEmployees.forEach( f => {
        this.documentEmployeesId.push(f.id);
      });

      const formData = {
        type: 'downloadIsDocuments',
        employees: this.documentEmployeesId
      };
      this.documentsService.massAction(formData).subscribe(r => {
        this.dialog.open(SuccessDialogComponent, {
          maxWidth: '100%',
          width: '350px',
          data: {
            text: 'Nezabudnite na podpísanie a vytlačenie nových oprávnení',
            action: 'updateJobIs'
          },
          autoFocus: false
        });
        this.isLoading = false;
        this.dialogRef.close(true);
      }, error => {
        this.snackBarService.snack('Vyskytol sa problém.');
        this.isLoading = false;
      });

    }
  }

  removeEmployee($event: MouseEvent, employee: Employee): void {
    // tslint:disable-next-line:triple-equals
    this.employees = this.employees.filter(f => f.id != employee.id);
  }
}
