import { Component, OnInit } from '@angular/core';
import localeSk from '@angular/common/locales/sk';
import {registerLocaleData} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {FirmService} from '../../../core/services/firm.service';
import {UserService} from '../../../core/services/user.service';
registerLocaleData(localeSk, 'sk');

@Component({
  selector: 'app-signpost',
  template: `<div class="signpost-wrap">
                <app-simple-header></app-simple-header>
                <app-snack-bar></app-snack-bar>
                <div class="container">
                  <router-outlet></router-outlet>
                </div>
              </div>`,
})
export class SignpostComponent implements OnInit {


  constructor(
    public route: ActivatedRoute,
    private firmService: FirmService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.firmService.firm = this.route.snapshot.data.firm;
    this.userService.user = this.route.snapshot.data.user;
  }

}
