import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InformationSystems} from '../../../core/models/domain/employees';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SnackBarService} from '../../../core/services/snack-bar.service';
import {JobsService} from '../../../core/services/jobs.service';
import {InformationSystemsService} from '../../../core/services/information-systems.service';
import {SuccessDialogComponent} from '../success-dialog/success-dialog.component';
import {JobValidator} from '../../../core/validators/jobValidator';

@Component({
  selector: 'app-new-job',
  templateUrl: './new-job.component.html',
  styleUrls: ['./new-job.component.scss']
})
export class NewJobComponent implements OnInit, AfterViewInit {
  formGroup: FormGroup;
  isLoading = false;
  submitted = false;
  informationSystems: InformationSystems[];

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public jobsService: JobsService,
    public dialogRef: MatDialogRef<NewJobComponent>,
    private informationSystemsService: InformationSystemsService,
    private snackBarService: SnackBarService,
    private cdr: ChangeDetectorRef,
    private jobValidator: JobValidator
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getIs();
    this.prepareForm();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  prepareForm(): void {
    this.formGroup = this.formBuilder.group({
      isForResponsibleEmployees: '1',
      name: ['', Validators.required, this.jobValidator.jobNameValidator()],
      is: []
    });
  }

  getIs(): void {
    this.informationSystemsService.getAllIs().subscribe(r => {
      this.informationSystems = r;
    });
  }

  get f(): any {
    return this.formGroup.controls;
  }

  onsubmit(): void {
    this.submitted = true;
    this.isLoading = true;

    if (this.formGroup.invalid) {
      this.isLoading = false;
      return;
    }

    if (!this.formGroup.value.is) {
      this.formGroup.get('is').patchValue([]);
    }
    const data = this.formGroup.get('isForResponsibleEmployees').value;
    this.formGroup.get('isForResponsibleEmployees').patchValue(+data);

    this.jobsService.store(this.formGroup.value).subscribe(r => {
      this.dialog.open(SuccessDialogComponent, {
        maxWidth: '100%',
        width: '350px',
        data: {
          text: 'Pracovná pozícia bola pridaná',
          action: 'success'
        },
        autoFocus: false
      });

      this.dialogRef.close();

    }, error => {
      this.snackBarService.snack('Vyskytol sa problém.');
      this.isLoading = false;
    });

  }
}
