import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadComponent } from './download.component';
import { FileComponent } from './file/file.component';
import {ThemeModule} from '../../theme/theme.module';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule, Routes} from '@angular/router';
import {MaterialModule} from '../../modules/material/material.module';

const routes: Routes = [
  {
    path: '',
    component: DownloadComponent,
    children: [
      {
        path: 'download/:hash',
        component: FileComponent,
      },
    ]
  }
];

@NgModule({
  declarations: [
    FileComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    RouterModule,
    ThemeModule,
    SharedModule,
    MaterialModule
  ]
})
export class DownloadModule { }
