import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./views/page/non-auth-zone/non-auth-zone.module')
      .then(m => m.NonAuthZoneModule),
  },
  {
    path: 'employee-zone',
    loadChildren: () => import('./views/page/employee-zone/employee-zone.module')
      .then(m => m.EmployeeZoneModule),
  },
  {
    path: 'signpost',
    loadChildren: () => import('./views/page/signpost/signpost.module')
      .then(m => m.SignpostModule),
  },
  {
    path: '',
    loadChildren: () => import('./views/page/dashboard/dashboard.module')
      .then(m => m.DashboardModule),
  },
  {
    path: 'setting',
    loadChildren: () => import('./views/page/setting/setting.module')
      .then(m => m.SettingModule)
  },
  {
    path: 'file',
    loadChildren: () => import('./views/page/download/download.module')
      .then(m => m.DownloadModule)
  },

  {
    path: 'skolenie',
    loadChildren: () => import('./views/page/redirection/redirection.module')
      .then(m => m.RedirectionModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
