import {Component, Inject, OnInit} from '@angular/core';
import {InformationSystems, Job} from '../../../core/models/domain/employees';
import {InformationSystemsService} from '../../../core/services/information-systems.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {JobsService} from '../../../core/services/jobs.service';
import {JobIsComponent} from '../../page/setting/settings-options/setting-jobs/components/job-is/job-is.component';
import {SnackBarService} from '../../../core/services/snack-bar.service';

@Component({
  selector: 'app-is-dialog',
  templateUrl: './is-dialog.component.html',
  styleUrls: ['./is-dialog.component.scss']
})
export class IsDialogComponent implements OnInit {
  formGroup: FormGroup;
  isLoading = false;
  submitted = false;
  shake = false;
  job: Job;
  oldIs: InformationSystems[];
  informationSystems: InformationSystems[];

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public jobsService: JobsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<IsDialogComponent>,
    private snackBarService: SnackBarService,
    private informationSystemsService: InformationSystemsService,
  ) { }

  ngOnInit(): void {
    this.job = this.data.job;
    this.prepareForm();
    this.getIs();
  }

  getIs(): void {
    this.informationSystemsService.getAllIs().subscribe(r => {
      this.informationSystems = r;
      this.getJobIs();
    });
  }

  getJobIs(): void {
    this.jobsService.getJobIs(this.job.id).subscribe(r => {
      this.oldIs = r;
      if (r) {
        const is = [];
        r.forEach( f => {
          this.informationSystems.forEach(i => {
            if ( i.id === f.id ) {
              is.push(f.id);
            }
          });
        });
        this.formGroup.get('is').patchValue(is);
      }
    });
  }

  prepareForm(): void {
    this.formGroup = this.formBuilder.group({
      is: []
    });
  }

  onsubmit(): void {
    this.submitted = true;
    this.isLoading = true;

    if (this.formGroup.invalid) {
      this.isLoading = false;
      return;
    }

    if (this.job.isForResponsibleEmployees) {
      if (!this.formGroup.value.is || this.formGroup.value.is.length === 0) {
        this.shake = true;
        setTimeout(() => {
          this.shake = false;
        }, 1000);
        this.snackBarService.snack('Vyberte informačný systém pre pracovnú pozíciu');
        this.isLoading = false;
        return;
      }
    }

    if (this.data && this.data.from === 'job' && this.job.countEmployees > 0) {
      const dialogRef = this.dialog.open(JobIsComponent, {
        width: '100%',
        maxWidth: '1100px',
        data: {
          job: this.job,
          data: this.data,
          oldIs: this.oldIs,
          is: this.informationSystems,
          formData: this.formGroup.value
        },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.dialogRef.close(true);
        }
      });
    } else {
      this.jobsService.syncIs(this.job.id, this.formGroup.value).subscribe(r => {
        this.dialogRef.close(true);
      }, error => {
        this.isLoading = false;
      });
    }
    this.isLoading = false;
  }

}
