import { Component, OnInit } from '@angular/core';
import {SnackBarService} from '../../../core/services/snack-bar.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {EmployeesService} from '../../../core/services/employees.service';

@Component({
  selector: 'app-new-employees',
  templateUrl: './new-employees.component.html',
  styleUrls: ['./new-employees.component.scss']
})
export class NewEmployeesComponent implements OnInit {
  formGroup: FormGroup;
  isLoading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<NewEmployeesComponent>,
    private snackBarService: SnackBarService,
    public employeesService: EmployeesService,
  ) { }

  ngOnInit(): void {
  }

  instruction($event: MouseEvent): void {
    this.router.navigate(['/instruction-csv']).then(() => {
      this.dialogRef.close();
    });
  }

  changeListener(files: FileList): void{
    this.isLoading = true;
    this.employeesService.importEmployees(files[0]).subscribe(r => {
      this.router.navigate(['/setting/import-check/generally']).then(() => {
        this.isLoading = false;
        this.dialogRef.close();
      });
    }, error => {
      this.snackBarService.snack('Nastala chyba');
      this.isLoading = false;
    });
  }
}
