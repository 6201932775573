import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SnackBarComponent} from './snack-bar/snack-bar.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../modules/material/material.module';
import {DndDirective} from '../../core/directive/dnd.directive';
import {LoaderComponent} from './loader/loader.component';
import { NewEmployeeComponent } from './new-employee/new-employee.component';
import { NewEmployeesComponent } from './new-employees/new-employees.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { EnterPhoneComponent } from './enter-phone/enter-phone.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import { NewJobComponent } from './new-job/new-job.component';
import {CheckboxIsComponent} from './checkbox-is/checkbox-is.component';
import { IsDialogComponent } from './is-dialog/is-dialog.component';
import { EmployeeNoteComponent } from './employee-note/employee-note.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { ImportantNoticesComponent } from './important-notices/important-notices.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { ImportantGuidelinesComponent } from './important-guidelines/important-guidelines.component';
import { ChangeJobCredentialsComponent } from './change-job-credentials/change-job-credentials.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { NotFoundComponent } from './not-found/not-found.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    SnackBarComponent,
    ConfirmationDialogComponent,
    DndDirective,
    LoaderComponent,
    NewEmployeeComponent,
    NewEmployeesComponent,
    EnterPhoneComponent,
    NewJobComponent,
    CheckboxIsComponent,
    IsDialogComponent,
    EmployeeNoteComponent,
    BackToTopComponent,
    ImportantNoticesComponent,
    SuccessDialogComponent,
    ImportantGuidelinesComponent,
    ChangeJobCredentialsComponent,
    EmailConfirmationComponent,
    NotFoundComponent,
  ],
    exports: [
        SnackBarComponent,
        LoaderComponent,
        CheckboxIsComponent,
        BackToTopComponent,
    ],
    imports: [
        CommonModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MaterialModule,
        FormsModule,
        NgSelectModule,
        NgxIntlTelInputModule,
        AngularEditorModule,
        MatSlideToggleModule,
    ]
})
export class SharedModule { }
