import {Component, Inject, OnInit} from '@angular/core';
import {Animations} from '../../../../../../../core/data/animations';
import {Employee, Job} from '../../../../../../../core/models/domain/employees';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {JobsService} from '../../../../../../../core/services/jobs.service';
import {SnackBarService} from '../../../../../../../core/services/snack-bar.service';
import {EmployeesService} from '../../../../../../../core/services/employees.service';
import {DocumentsService} from '../../../../../../../core/services/documents.service';

@Component({
  selector: 'app-job-credentials',
  templateUrl: './job-credentials.component.html',
  styleUrls: ['./job-credentials.component.scss'],
  animations: [
    Animations.scaleAnimeTrigger,
    Animations.slideInOutAnimeTrigger,
  ]
})
export class JobCredentialsComponent implements OnInit {
  isLoading = false;
  submitted = false;
  employees: Employee[];
  documentEmployees: Employee[];
  documentEmployeesId: number[] = [];
  job: Job;

  constructor(
    public dialog: MatDialog,
    public jobsService: JobsService,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employeesService: EmployeesService,
    public documentsService: DocumentsService,
    public dialogRef: MatDialogRef<JobCredentialsComponent>,
  ) { }

  ngOnInit(): void {
    this.snackBarService.snack('Údaje pracovnej pozície boli aktualizované');
    this.job = this.data.data.job;
    this.employees = this.data.employees;
  }

  onsubmit(button: any): void {
    this.isLoading = true;
    button.isLoading = true;
    this.documentEmployees = this.employees;
    this.editMultiple(this.employees, button);
  }

  editMultiple(employees, button): void {
    if (employees.length > 0) {
      const employee = employees[0];

      const formData = {
        is: this.data.data.formData.is
      };

      this.employeesService.syncIsGroup(employee.id, formData).subscribe(r => {

        // tslint:disable-next-line:triple-equals
        this.employees = this.employees.filter(f => f.id != employees[0].id);
        this.editMultiple(this.employees, button);

      }, error => {
        this.snackBarService.snack('Vyskytol sa problém.');
        button.isLoading = false;
        this.isLoading = false;
      });
    } else {
      this.employeesService.employeeChangeIsGroup.next(true);

      this.documentEmployees.forEach( f => {
        this.documentEmployeesId.push(f.id);
      });

      const formData = {
        type: 'downloadIsDocuments',
        employees: this.documentEmployeesId
      };
      this.documentsService.massAction(formData).subscribe(r => {
        button.isLoading = false;
        this.isLoading = false;
        this.dialogRef.close(true);
      }, error => {
        this.snackBarService.snack('Vyskytol sa problém.');
        button.isLoading = false;
        this.isLoading = false;
      });

    }
  }

  removeEmployee($event: MouseEvent, employee: Employee): void {
    // tslint:disable-next-line:triple-equals
    this.employees = this.employees.filter(f => f.id != employee.id);
  }
}
