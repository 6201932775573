import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, timer} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AbstractControl, AsyncValidatorFn} from '@angular/forms';
import {isEmpty} from '../../../helper';
import {Job} from '../models/domain/employees';

@Injectable({
  providedIn: 'root'
})
export class JobValidator {
  constructor(private http: HttpClient) {
  }

  searchJobByName(name: string): Observable<Job> {
    // debounce
    return timer(500)
      .pipe(
        distinctUntilChanged(),
        switchMap(() => {
          // Check if invoice number is available
          return this.http.get<Job>(`${environment.baseUrl}/job/getByColumn/name/${name}`);
        })
      );
  }

  jobNameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this.searchJobByName(control.value)
        .pipe(
          map(res => {
            // if invoice number is already taken
            if (!isEmpty(res)) {
              // return error
              return {jobNameExist: true};
            }
          })
        );
    };
  }
}
