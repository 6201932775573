import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FileService} from '../../../../core/services/file.service';
import {SnackBarService} from '../../../../core/services/snack-bar.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {
  isLoading = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fileService: FileService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.params.hash != null) {
      const hash = this.route.snapshot.params.hash;
      this.fileService.getDownload(hash).subscribe(r => {
        if (r && r.info) {
          this.snackBarService.snack(r.info);
          this.isLoading = false;
        } else {
          this.downloadFile(r);
        }
      }, error => {
        this.isLoading = false;
      });
    }
  }

  downloadFile(r): any{
    const b64toBlob = (b64Data, contentType = r.mime, sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    };

    const blob = b64toBlob(r.data, r.mine);
    const blobUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    const fileName = r.name;

    downloadLink.href = blobUrl;
    downloadLink.download = fileName;
    downloadLink.click();
    this.isLoading = false;
    this.snackBarService.snack('Súbor bol stiahnutý');
  }

}
