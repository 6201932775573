import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div *ngIf="isLoading" class="load-wrap">
      <div id="loader">
        <div id="pace-wrap">
          <p>Načítava sa...</p>
        </div>
      </div>
    </div>`,
})
export class LoaderComponent implements OnInit {
  @Input() isLoading = false;

  constructor() {
  }

  ngOnInit(): void {
  }
}

