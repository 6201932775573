import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-non-auth-zone',
  template: `<div class="non-auth-wrap">
                <app-snack-bar></app-snack-bar>
                <app-simple-header></app-simple-header>
                <router-outlet></router-outlet>
              </div>`,
})
export class NonAuthZoneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
