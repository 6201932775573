import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Services} from '../models/domain/services';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  services: Services[];

  constructor(private http: HttpClient) { }

  createRequest(formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/service/request/create`, formData);
  }

  getAll(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/service/all`);
  }
}
