import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { CountryISO, SearchCountryField, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-enter-phone',
  templateUrl: './enter-phone.component.html',
  styleUrls: ['./enter-phone.component.scss']
})
export class EnterPhoneComponent implements OnInit {
  formGroup: FormGroup;
  submitted = false;
  onlyPhone = false;
  emptyTel = false;
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EnterPhoneComponent>,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.action === 'onlyPhone') {
      this.onlyPhone = true;
    }
    this.prepareForm();
  }

  private prepareForm(): void {
    this.formGroup = this.formBuilder.group({
      phone: '',
      downloadInvation: ''
    });

  }

  get f(): any {
    return this.formGroup.controls;
  }

  savePhone($event: MouseEvent): void {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    if (this.onlyPhone && !this.formGroup.get('phone').value) {
      this.emptyTel = true;
      return;
    }

    this.dialogRef.close(this.formGroup.value);
  }
}
