import {MenuItem} from '../models/domain/menu-items';


export const MENUITEMS: MenuItem[] = [
  {
    title: 'Domov',
    icon: 'home',
    routerLink: 'home',
    isActive: false,
  },
  {
    title: 'Zamestnanci',
    icon: 'people',
    routerLink: 'employees',
    isActive: false,
  },
  {
    title: 'Dokumenty',
    icon: 'folder',
    routerLink: 'documents',
    isActive: false,
  },
  {
    title: 'Incidenty',
    icon: 'security',
    routerLink: 'security-motions',
    isActive: false,
  },
  {
    title: 'Nastavenia',
    icon: 'settings',
    routerLink: 'setting',
    isActive: false,
  },
];

export const NAVSETTINGS: MenuItem[] = [
  {
    title: 'Všeobecné nastavenia',
    icon: 'generally',
    type: 'generally',
    isActive: false,
    isType: true,
  },
  {
    title: 'GDPR nastavenia',
    icon: 'gdpr',
    type: 'gdpr',
    isActive: false,
    testType: 'GDPR',
    testTypeId: 1
  },
  {
    title: 'KB nastavenia',
    icon: 'kb',
    type: 'kb',
    isActive: false,
    testType: 'KYBERNETICKÁ BEZPEČNOSŤ',
    testTypeId: 3
  },
  {
    title: 'KŠ nastavenia',
    icon: 'cyberbullying',
    type: 'cyberbullying',
    isActive: false,
    testType: 'Kyberšikana',
    testTypeId: 4
  },
];

export const MENUSETTINGS: MenuItem[] = [
  {
    title: 'Pracovné pozície',
    routerLink: 'jobs',
    types: ['generally'],
    isActive: false,
  },
  {
    title: 'Testovacie skupiny',
    routerLink: 'test-type',
    types: ['generally'],
    isActive: false,
  },
  {
    title: 'Dokumenty',
    routerLink: 'documents',
    types: ['generally'],
    isActive: false,
  },
  {
    title: 'Emaily a Automatické správy',
    routerLink: 'email-templeate',
    types: [ 'gdpr', 'kb', 'cyberbullying'],
    isActive: false,
  },
  {
    title: 'SMTP nastavenia',
    routerLink: 'smtp',
    types: [ 'generally'],
    isActive: false,
  },
  {
    title: 'Export dát do SAPu',
    routerLink: 'sap',
    types: ['generally'],
    isActive: false,
  },
  {
    title: 'Import zamestnancov',
    routerLink: 'import-check',
    types: ['generally'],
    isActive: false,
  },
];
