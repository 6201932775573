import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SimpleHeaderComponent } from './simple-header/simple-header.component';
import {MaterialModule} from '../modules/material/material.module';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {MatMenuModule} from '@angular/material/menu';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';



@NgModule({
  declarations: [HeaderComponent, SimpleHeaderComponent, MenuComponent, FooterComponent],
  exports: [
    HeaderComponent,
    SimpleHeaderComponent,
    MenuComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MaterialModule,
    SharedModule,
  ]
})
export class ThemeModule { }
