import {Component, Input, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {MENUSETTINGS} from '../../../../core/data/menu-items';

@Component({
  selector: 'app-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss']
})
export class SettingsMenuComponent implements OnInit {
  @Input() type: string;
  menuItems = MENUSETTINGS;

  constructor(

  ) {
  }

  ngOnInit(): void {
  }

}
