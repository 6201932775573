import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../core/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../core/services/user.service';
import {AppMenuService} from '../../../core/services/app-menu.service';
import {Services} from '../../../core/models/domain/services';
import {ServicesService} from '../../../core/services/services.service';
import {User} from '../../../core/models/domain/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: User;
  isLoading = false;
  services: Services[];

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private menuService: AppMenuService,
    public servicesService: ServicesService,
  ) { }

  ngOnInit(): void {
    this.user = this.userService.user;
    this.services = this.servicesService.services;
    if(!this.services) {
      this.getServices();
    }
  }

  getServices(): void {
    this.servicesService.getAll().subscribe(r => {
      this.services = r;
      this.servicesService.services = r;
    });
  }

  logout(): void {
    this.authService.signOut();
    this.router.navigate(['/auth/login']).then();
  }

  onClick($event: MouseEvent): void {
    this.router.navigate(['/']).then(() => {
      this.menuService.setMenuIndexActive.next(0);
    });
  }
}
