import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Job} from '../models/domain/employees';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {State} from '../models/state';
import {Paginate} from '../models/paginate';
import {prepareFilter, prepareFilterJson} from '../../../helper';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  state: State<any> = {
    page: 0,
    pageSize: 50,
    queryString: ''
  };

  jobsChange = new Subject<Job>();

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/job/all`);
  }

  paginate(): Observable<Paginate<Job>> {
    const f = JSON.parse(JSON.stringify(this.state));
    f.page++;

    let httpParams = prepareFilter(f);

    if (f.sort) {
      httpParams = prepareFilterJson(httpParams, f.sort, 'sort');
    }

    return this.http.get<Paginate<Job>>(`${environment.baseUrl}/job/paginate`, {params: httpParams});
  }

  store(formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/job/store`, formData).pipe(map(r => {
      this.jobsChange.next(r);

      return r;
    }));
  }

  update(id: number, formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/job/${id}/update`, formData);
  }

  syncIs(id: number, formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/job/${id}/syncIs`, formData);
  }

  exportCsv(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/job/all/csv`);
  }

  getEmployees(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/job/${id}/employees`);
  }

  set(id: number, key: string, formData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/job/${id}/set/${key}`, formData);
  }

  destroy(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/job/destroy/${id}`);
  }

  getJobIs(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/job/${id}/is`);
  }

  getJobOne(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/job/${id}`);
  }
}
