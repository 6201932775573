import { Component, OnDestroy, OnInit } from '@angular/core';
import { MENUITEMS } from '../../../core/data/menu-items';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FirmService } from '../../../core/services/firm.service';
import { UserService } from '../../../core/services/user.service';
import { Subscription } from 'rxjs';
import { DocumentsService } from '../../../core/services/documents.service';
import { ImportantGuidelinesComponent } from '../../shared/important-guidelines/important-guidelines.component';
import { MatDialog } from '@angular/material/dialog';
import {Guidance} from '../../../core/models/domain/documents';
import {SETTINGTYPES} from '../../../core/data/settings';

@Component({
  selector: 'app-setting',
  template: `<div class="setting-wrap">
                <app-snack-bar></app-snack-bar>
                <div class="container">
                  <app-header></app-header>
                  <app-menu [menuItems]="menuItems"></app-menu>
                  <app-settings-index></app-settings-index>
                  <app-settings-nav [type]="type"></app-settings-nav>
                  <div class="box-wrap">
                    <app-settings-menu [type]="type"></app-settings-menu>
                    <router-outlet></router-outlet>
                  </div>
                  <app-footer></app-footer>
                  <app-back-to-top></app-back-to-top>
                </div>
              </div>`,
})
export class SettingComponent implements OnInit, OnDestroy {
  type: string;
  types: string[] = SETTINGTYPES;
  menuItems = MENUITEMS;
  guidelines: Guidance[] = [];
  routerSubscription: Subscription;
  routerSubscriptionSettings: Subscription;

  constructor(
    private route: ActivatedRoute,
    private firmService: FirmService,
    private userService: UserService,
    public router: Router,
    public dialog: MatDialog,
    private documentsService: DocumentsService
  ) {
    this.routerSubscriptionSettings = router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd){
        this.type = y.urlAfterRedirects.substr(y.urlAfterRedirects.lastIndexOf('/') + 1);
        if (!this.types.includes(this.type)) {
          this.router.navigate(['/setting/404']).then();
        }
      }
    });

  }

  ngOnInit(): void {
    this.firmService.firm = this.route.snapshot.data.firm;
    this.userService.user = this.route.snapshot.data.user;

    this.getAllGuidelines();
  }

  ngOnDestroy(): void {
    if (this.guidelines && this.guidelines.length > 0) {
      this.routerSubscription.unsubscribe();
    }
    this.routerSubscriptionSettings.unsubscribe();
  }

  showGuidelines(): void {
    const dialogRef = this.dialog.open(ImportantGuidelinesComponent, {
      backdropClass: 'guidelines-dialog',
      width: '100%',
      data: {
        guidelines: this.guidelines
      },
      maxWidth: '1100px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.routerSubscription.unsubscribe();
        this.getAllGuidelines();
      }
    });
  }

  private getAllGuidelines(): void {
    this.documentsService.getAllGuidelinesNotRead().subscribe(event => {
      this.guidelines = event;
      if (this.guidelines && this.guidelines.length > 0) {
        this.showGuidelines();
        this.routerSubscription = this.router.events.subscribe(e => {
          if (e instanceof NavigationEnd) {
            if (this.guidelines && this.guidelines.length > 0) {
              this.showGuidelines();
            }
          }
        });
      }
    });
  }
}
